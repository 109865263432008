import React, { useState, useEffect } from 'react';
import {
    Layout,
    Row,
    Input,
    Tag,
    Typography,
    Table,
    message,
    Tabs,
    Spin,
    Space,
    DatePicker,
    Tooltip,
    Button,
    InputNumber,
    Form,
    Col,
    Drawer,
    List,
} from 'antd';
import { QuestionCircleOutlined, PlusOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { GET_REQUESTS } from '../../queries';
import { UPDATE_REQUEST } from '../../mutations';
import { useParams } from 'react-router-dom';
import UpdateRequestButton from './UpdateRequestButton';
import { authenticationService } from '../../Utilities/authenticationService';
import moment from 'moment';
import { useStickyState } from '../../Utilities/useStickyState';
import { filterRequestsByTabAndSearchText } from '../../Utilities/searchUtils';
import NewRequestDrawer from './NewRequestDrawer';
import { localeInDatepicker } from '../../Utilities/datepickerWithTimeInForm';
import { isMobile } from '../../Utilities/isMobile';

const RequestsList = () => {
    const { data: requestsData, loading: loadingRequests, refetch } = useQuery(GET_REQUESTS, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [sentUpdateRequest, setSentUpdateRequest] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const { tabName, requestId } = useParams();
    const [form] = Form.useForm();
    const [tabFilter, setTabFilter] = useStickyState('sent', 'requestsListTabFilter', tabName);
    const [filterBy, setFilterBy] = useState('sent')
    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        refetchQueries: [{ query: GET_REQUESTS }],
        onCompleted: (data) => {
            if (data.updateRequest.errors?.[0] === 'Truck not found') {
                message.error('選択したトラックが見つかりませんでした。')
            } else if (data.updateRequest.request?.overlaps?.length > 0 || (data.updateRequest.errors?.length > 0 && data.updateRequest.errors[0].includes("overlap"))) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else { message.success('リクエストが更新されました'); refetch() }
        },
        onError: (error) => { 
            message.error('リクエストの更新に失敗しました。')
        }
    });

    const currentUser = authenticationService.currentUserValue

    useEffect(() => {
        const filteredRows = filterRequestsByTabAndSearchText(originalRows, tabFilter, searchText);
        setRows(filteredRows);
    }, [tabFilter, searchText, originalRows, selectedDate])

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchText(value);
    };

    const handleDateChange = (date, dateString) => {
        setSelectedDate(moment(dateString));
    };

    const handleTruckUpdate = (record, vehicleId) => {
        if (record.status === 'rejected') {
            message.success('拒否済みリクエストにトラックを付与しようとしています。付与が成功した場合は承認待ち状態に変更されます')
            updateRequest({ variables: { input: { id:record.id, vehicleId: vehicleId, status: 'sent' }}});
        } else {
            updateRequest({ variables: { input: { id:record.id, vehicleId: vehicleId }}});
        }
    };

    const getStatusTag = (status) => {
        let result;
            switch(status) {
                case 'sent':
                    result = <Tag color='orange'>承認待ち</Tag>
                    break;
                case 'accepted':
                    result = <Tag color='green'>承認済み</Tag>
                    break;
                case 'rejected':
                    result = <Tag color='red'>拒否済み</Tag>
                    break;
                default:
                    break;
            }
            return result;
    };

    const SearchEreaForMobile = () => {
        return (<Space size={24} direction="vertical" style={{ width: "100%" }}>
            <Form 
                form={form} 
                layout='inline'
                size='large'
                onFinish={
                    ({ date, text }) => {
                        handleDateChange('', date.format('YYYY-MM-DD'));
                        handleSearchChange(text);
                    }
                }
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='date'
                            label={isMobile() ? "" : "表示期間"}
                        >
                            <DatePicker
                                locale={localeInDatepicker}
                                label={"本日の日付"}
                                style={{ width: 150 }}
                                allowClear={false} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='text'>
                            <Input
                                allowClear
                                style={{ width: 150 }}
                                placeholder="例：冷凍車、バン"
                                prefix={<SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item shouldUpdate>
                            {
                                () => <Button htmlType="submit" type="primary">検索</Button>
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Space>)
    }

    const updateVehicleId = (record, value) => (
        <Form
            size='small'
            style={{ display: 'flex' }}
            onFinish={({ vehicleId }) => { handleTruckUpdate(record, vehicleId) }}
        >
            <Form.Item name='vehicleId' style={{margin: 'auto'}}>
                <InputNumber placeholder="車両番号" value={value}/>
            </Form.Item>
            <Form.Item shouldUpdate style={{margin: 'auto'}}>
                <Button htmlType='submit' type='link' size='small'>更新</Button>
            </Form.Item>
        </Form>
    )

    useEffect(() => {
        if (requestsData) {
            if (tabName && requestId && !sentUpdateRequest) {
                updateRequest({ variables: { input: { id: requestId, status: tabName }}});
                setSentUpdateRequest(true);
            }
            const parsedRows = requestsData.requests.edges.filter(r => {
                if (r.node.status === 'accepted') {
                    return r.node.sender?.id !== r.node.user?.id
                }
                return true
            }).filter(row => selectedDate ? moment(row.date).isSameOrAfter(selectedDate) : filterBy === 'sent' ? true : parseInt(row.node.user.id) === currentUser.id)
            .map(request => ({
                key: request.node.id,
                id: request.node.id,
                date: request.node.opsDate,
                departureTime: request.node.routesForecast?.departureTime,
                arrivalTime: request.node.routesForecast?.arrivalTime,
                from: request.node.from?.officialName,
                to: request.node.to?.officialName,
                remarks: request.node.remarks,
                vehicleType: request.node.truck?.vehicleType || '未登録',
                vehicleId: request.node.truck?.vehicleId || '',
                licenseType: request.node.truck?.driverLicenseClass || '未登録',
                sender: request.node.sender,
                approver: request.node.user,
                status: request.node.status,
                updatedAt: request.node.updatedAt,
                old: new Date(request.node.opsDate) <= moment() ? true : false,
                route: request.node.routesForecast?.route?.route,
                label: request.node.routesForecast?.label?.label,
                capacity: request.node.truck?.maxTruckCapacity || '未登録',
                price: request.node.price,
                employeeName: request.node.routesForecast?.employeeName || '未登録'
            })).sort((a, b) => new Date(b.departureTime) - new Date(a.departureTime));
            setRows(parsedRows)
            setOriginalRows(parsedRows)
        }
    }, [requestsData, tabFilter, tabName, requestId, updateRequest, sentUpdateRequest, selectedDate, filterBy, currentUser.id]);

    const statusUpdateButtons = (row) => {
        return <Space>
        {
            (tabFilter === 'rejected' || tabFilter === 'sent') && parseInt(row.approver.id) === currentUser.id &&
                <UpdateRequestButton disabled={row.vehicleId.length === 0} refetch={refetch} id={row.id} status='accepted' content='承認' />
        }
        {
            (tabFilter === 'accepted' || tabFilter === 'sent') && parseInt(row.approver.id) === currentUser.id &&
                <UpdateRequestButton refetch={refetch} id={row.id} status='rejected' content='拒否' />
        }
        </Space>
    }
    const columns = [
        {
            title: <Typography.Text strong>依頼元</Typography.Text>,
            dataIndex: 'from',
            width: 150
        }, {
            title: <Typography.Text strong>依頼先</Typography.Text>,
            dataIndex: 'to',
            width: 150
        }, {
            title: <Typography.Text strong>ルート名</Typography.Text>,
            dataIndex: 'label',
            render: rowContent => <>{rowContent?.length > 0 ? rowContent.join('|') : ''}</>,
            ellipsis: true,
            width: 170
        }, {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'departureTime',
            sorter: (a, b) => new Date(a.departureTime) - new Date(b.departureTime),
            defaultSortOrder: 'descend',
            render: rowContent => moment(rowContent).format('YYYY-MM-DD HH:mm'),
            width: 200
        }, {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'arrivalTime',
            sorter: (a, b) => new Date(a.arrivalTime) - new Date(b.arrivalTime),
            render: rowContent => moment(rowContent).format('YYYY-MM-DD HH:mm'),
            width: 200
        }, {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            width: 100
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            render: (rowContent, record) => {
                if (!rowContent && record.approver.id === String(currentUser.id)) {
                    return updateVehicleId(record, rowContent)
                } else {
                    return <>{rowContent}</>
                }
            },
            width: 200
        }, {
            title: <Typography.Text strong>備考欄</Typography.Text>,
            dataIndex: 'remarks',
            width: 200
        }, {
            title: <Typography.Text strong>最大積載量（キロ）</Typography.Text>,
            dataIndex: 'capacity',
            width: 160
        },
        {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'licenseType',
            width: 120
        }, {
            title: <Typography.Text strong>配送者</Typography.Text>,
            dataIndex: 'employeeName',
            width: 100
        },
        {
            title: <Typography.Text strong>送信者</Typography.Text>,
            dataIndex: 'sender',
            render: rowContent => <>{rowContent.lastName}{rowContent.firstName}</>,
            width: 100
        }, {
            title: <Typography.Text strong>承認者</Typography.Text>,
            dataIndex: 'approver',
            render: rowContent => <>{rowContent.lastName}{rowContent.firstName}</>,
            width: 100
        },
        {
            title: <Typography.Text strong>運賃</Typography.Text>,
            dataIndex: 'price',
            render: rowContent => !rowContent ? <>{"-"}</> : <>{rowContent}</>,
        },

        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'status',
            render: rowContent => getStatusTag(rowContent),
            width: 150
        }, {
            title: '',
            render: (rowContent, record) => {
                return rowContent && statusUpdateButtons(record)},
            width: 150
        }
    ].filter(Boolean);

    const items = [
        {
            key: 'sent',
            label: '承認待ち'
        },
        {
            key: 'accepted',
            label: '承認済み'
        },
        {
            key: 'rejected',
            label: '拒否済み'
        },
        {
            key: 'old',
            label: '過去のリクエスト'
        }
    ];
    const RequestForMobile = () => {
        return (
            <div style={{marginBottom: '5rem'}}>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Button type='text' icon={<FilterOutlined />} onClick={() => setOpenFilterDrawer(true)}>フィルター</Button>
                    <Button type='text' icon={<PlusOutlined />} style={{margin: '15px', zIndex: drawerOpen ? 0 : 1001, top: 0, right: 0, position: 'fixed'}} onClick={() => setDrawerOpen(true)}/>
                </Row>
                <Drawer
                    title="フィルター"
                    placement="bottom"
                    closable={true}
                    onClose={() => setOpenFilterDrawer(false)}
                    open={openFilterDrawer}
                >
                    <List>
                        <List.Item
                            onClick={() => {setFilterBy('sent'); setOpenFilterDrawer(false)}}    
                        >
                            <Typography.Text strong>承認待ちリクエストを全て表示</Typography.Text>
                        </List.Item>
                        <List.Item
                            onClick={() => {setFilterBy('mine'); setOpenFilterDrawer(false)}}
                        >
                            <Typography.Text strong>自分の承認待ちリクエストのみ表示</Typography.Text>
                        </List.Item>
                    </List>
                </Drawer>
                {rows.length === 0 && <Typography.Text>リクエストがありません</Typography.Text>}
                {rows.map(row => (
                    <div key={row.id} style={{ border: '1px solid #f0f0f0', padding: '10px', margin: '10px 0px' }} >
                        <Typography.Text strong>依頼元: </Typography.Text>{row.from}<br/>
                        <Typography.Text strong>依頼先: </Typography.Text>{row.to}<br/>
                        <Typography.Text strong>ルート名: </Typography.Text>{row.label}<br/>
                        <Typography.Text strong>出発時刻: </Typography.Text>{moment(row.departureTime).format('YYYY-MM-DD HH:mm')}<br/>
                        <Typography.Text strong>到着時刻: </Typography.Text>{moment(row.arrivalTime).format('YYYY-MM-DD HH:mm')}<br/>
                        <Typography.Text strong>車種名: </Typography.Text>{row.vehicleType}<br/>
                        <Row>
                            <Typography.Text strong>車両番号: </Typography.Text>
                            {row.vehicleId ? row.vehicleId : row.approver.id === String(currentUser.id) ? updateVehicleId(row, row.vehicleId): '選択されていません'}
                        </Row>
                        <Typography.Text strong>備考欄: </Typography.Text>{row.remarks}<br/>
                        <Typography.Text strong>最大積載量（キロ）: </Typography.Text>{row.capacity}<br/>
                        <Typography.Text strong>免許タイプ: </Typography.Text>{row.licenseType}<br/>
                        <Typography.Text strong>配送者: </Typography.Text>{row.employeeName}<br/>
                        <Typography.Text strong>送信者: </Typography.Text>{row.sender.lastName}{row.sender.firstName}<br/>
                        <Typography.Text strong>承認者: </Typography.Text>{row.approver.lastName}{row.approver.firstName}<br/>
                        <Typography.Text strong>運賃: </Typography.Text>{row.price || '-'}<br/>
                        <Typography.Text strong>ステータス: </Typography.Text>{getStatusTag(row.status)}<br/>
                        {statusUpdateButtons(row)}
                    </div>
                ))}
            </div>
        )
    }
    if (loadingRequests) {
        return <Spin/>;
    }

    return <Layout style={{ padding: '0px 24px', marginTop: 15, background: '#fff' }}>
        <Space direction='vertical' style={{ width: '100%'}}>
            {isMobile() && <SearchEreaForMobile />}
            <Tabs defaultActiveKey={tabFilter} onChange={ activeKey => setTabFilter(activeKey) } items={items} style={{ width: '100%'}} />
            {isMobile() ? <RequestForMobile /> : <>
                <Row>
                    <div style={{ width: '50%' ,padding: '0px 24px 15px 0px', background: '#fff' }}>
                        <Input.Search style={{ width: '300px' }} value={searchText} onChange={handleSearchChange} allowClear placeholder="検索" />
                        <DatePicker locale={localeInDatepicker} label={"本日の日付"} value={selectedDate} onChange={handleDateChange} style={{ marginLeft: '10px' }} allowClear={false} />
                        <Tooltip color='blue' title={<span>この日付以降のリクエストを表示中です。それ以前を表示したい場合は日付を変更するか、一番右の「過去のリクエスト」タブを選択してください。</span>}>
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }}  />
                        </Tooltip>
                    </div>
                    <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => setDrawerOpen(true)}>リクエストを作成</Button>
                    </div>
                </Row>
                <Table
                    rowClassName={(record) => record.key === requestId || parseInt(record.approver.id) === currentUser.id ? 'highlighted-row' : '' }
                    columns={columns}
                    dataSource={rows}
                    scroll={{ x: "max-content" }}
                />
            </>}
        </Space>
        <NewRequestDrawer
            setVisible={setDrawerOpen}
            visible={drawerOpen}
            refetch={refetch}
        />
    </Layout>
};

export default RequestsList;
