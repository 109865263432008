import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPDATE_STATION } from '../../mutations'
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddStationModal from './AddStationModal';
import NewStationModal from './NewStationModal';
import DeleteStationsButton from './DeleteStationsButton';

const StationManagement = ({ stationsQuery }) => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newStationCreated, setNewStationCreated] = useState(false);
    const [stationsDeleted, setStationsDeleted] = useState(false);
    const [selectedStationIds, setSelectedStationIds] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { data, error, loading, refetch } = stationsQuery
    const [updateStation] = useMutation(UPDATE_STATION, {
        onCompleted: (data) => {
            refetch();
        }
    });

    const [rows, setRows] = useState([]);


    const columns = [
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName'
        },
        {
            dataIndex: 'officialName',
            title: <Typography.Text strong>営業所(official)</Typography.Text>,
        },
        {
            dataIndex: 'isOpen',
            title: <Typography.Text strong>営業中</Typography.Text>,
            render: (text, record) => {
                const stationName = record.stationName
                const id = record.key
                const handleOpenSelect = (value) => {
                    updateStation({ variables: { input: { id, stationName, isOpen: value.target.checked }}})
                }
                return (
                    <Checkbox onChange={handleOpenSelect} checked={record.isOpen}></Checkbox>
                )
            }
        },
        {
            dataIndex: ['company', 'companyName'],
            title: <Typography.Text strong>会社ID</Typography.Text>,
            
        },
        {
            dataIndex: 'longtitude',
            title: <Typography.Text strong>経度</Typography.Text>,
        },
        {
            dataIndex: 'latitude',
            title: <Typography.Text strong>緯度</Typography.Text>,
        },
        {
            dataIndex: 'district',
            title: <Typography.Text strong>地区</Typography.Text>,
        },
    ];


    useEffect(() => {
        if (!loading) {
            setRows(
                data.stations.edges.map(
                    station => {
                        if (station.node.stationName.includes(searchText)) {
                            return {
                                key: station.node.id,
                                stationName: station.node.stationName,
                                officialName: station.node.officialName,
                                isOpen: station.node.isOpen,
                                companyId: station.node.companyId,
                                longtitude: station.node.longtitude,
                                latitude: station.node.latitude,
                                district: station.node.district,
                                company: station.node.company
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean).sort((a, b) => a.key - b.key)
            )
        }
    }, [searchText, data, loading])


    if (loading) { return <Skeleton />; }

    if (error) {
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const handleSearch = searchField => {
        setSearchText(searchField.target.value);
    };

    const currentUser = JSON.parse(localStorage.currentUser);
    
    const TableHeader = <React.Fragment>
        <Row>
            <Col align='left' style={{ padding: '0px', background: '#fff' }}>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            {  (currentUser?.admin || currentUser?.company_admin) && <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addStation') } icon={<PlusOutlined/>}>営業所名追加</Button>
                    <DeleteStationsButton
                        selectedStationIds={selectedStationIds}
                        handleCompleted={ (stationInfo) => { setCurrentModal('table'); setStationsDeleted(stationInfo); refetch(); }} />
                </Space>
            </Col>}
        </Row>
        <Row>
            { newStationCreated &&
                <>
                    <Col span={24}>
                        <Alert message="営業所を追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewStationModal stationInfo={newStationCreated} />
                </>
            }
            { stationsDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`"${stationsDeleted.map(c => c.stationName)}"を削除しました。`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedStationIds(selectedRows.map(row => row.key));
        }
    };

    const returnToTable = () => {
        setCurrentModal('table');
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addStation' &&
            <AddStationModal
                handleCompleted={ (stationInfo) => { setCurrentModal('table'); setNewStationCreated(stationInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default StationManagement;
