import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_TRUCK_POSTING_COMMENT } from '../../mutations';
import { GET_USERS, GET_TRUCK_POSTING_COMMENT } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService';
import {
    Button,
    Drawer,
    Typography,
    Avatar,
    Divider,
    Popconfirm,
    Row,
    Col
} from 'antd';
import { CloseOutlined, MessageOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from '../../Utilities/isMobile';

import CommentList from '../FreightPosting/CommentList';
import CommentDrawer from '../FreightPosting/CommentDrawer';


const DetailDrawer = ({ visible=false, setVisible, handleDeletePosting,  posting = {}, refetchPostings, buttonMode, traboxButton, postingData }) => {
    const currentUser = authenticationService.currentUserValue
    const [commenterIds, setCommenterIds] = useState([]);
    const [comments, setComments] = useState([]);
    const [selectedCommenterId, setSelectedCommenterId] = useState();
    const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
    const { data: userListData } = useQuery(GET_USERS);

    const { refetch: refetchComments } = useQuery(GET_TRUCK_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(posting.id) },
        onCompleted: (data) => {
            const fromUserIds = data.posting?.truckPostingComments.map(comment => comment.fromUser.id)
            const toUserIds = data.posting?.truckPostingComments.map(comment => comment.toUser?.id)
            const commenters = [...new Set([...fromUserIds, ...toUserIds])].filter(commenter => String(commenter) !== String(posting?.userId))
            setCommenterIds(commenters)

            const commentArr = data.posting?.truckPostingComments.map(comment => ({
                id: comment.id,
                postingId: posting.id,
                fromId: comment.fromUser.id,
                toId: comment.toUser?.id,
                author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                content: (
                    <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{comment.commentText}</p>
                        <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                            {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')}
                        </span>
                    </div>
                ),
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr)        
        }
    });    

    const [createTruckPostingComment] = useMutation(CREATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
            refetchComments();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const columnList = ['営業所', '会社名', '運行日', '出発時刻', '到着時刻', '車種', '車両番号', '積載重量', '免許タイプ', '投稿者']
    const labelNameMapping = {
        '営業所': 'station',
        '会社名': 'companyName',
        '運行日': 'opsDate',
        '出発時刻': 'startDate',
        '到着時刻': 'endDate',
        '車種': 'vehicleType',
        '車両番号': 'vehicleId',
        '積載重量': 'maxTruckCapacity',
        '免許タイプ': 'driverLicenseClass',
        '投稿者': 'postedByUserName'
    }
    return <>
        {buttonMode ? <Button icon={<MessageOutlined/>} onClick={() => setVisible(true)}/> : <Button type='link' onClick={() => setVisible(true)}>リクエスト</Button>}
        <Drawer
            width={isMobile() ? '100vw' : 450}
            open={visible}
            closable={false}
            style={{ zIndex: 1002 }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    margin: '-10px 0px'
                }}
            >
                <Popconfirm
                    title="投稿を削除しますか？"
                    okText="削除"
                    cancelText="キャンセル"
                    placement='bottomLeft'
                    onConfirm={() => handleDeletePosting(posting.id)}
                >
                    <DeleteOutlined
                        style={{
                            marginRight: 5,
                            fontSize: 16,
                            zIndex: 1,
                        }}
                    />
                </Popconfirm>
                <Button
                    type='text'
                    onClick={() => setVisible(false)}
                    style={{ zIndex: 1 }}
                >
                    <CloseOutlined />
                </Button>
            </div>
            <Divider style={{width: '112%', marginLeft: '-1.5rem'}} />
            <CommentList 
                posting={posting}
                commenterIds={commenterIds}
                setSelectedCommenterId={setSelectedCommenterId}
                setOpenCommentDrawer={setOpenCommentDrawer}
                userListData={userListData}
            />
            <CommentDrawer
                open={openCommentDrawer}
                setOpen={setOpenCommentDrawer}
                comments={comments}
                selectedCommenterId={selectedCommenterId}
                toUser={userListData?.users?.edges?.find(user => user.node.id === selectedCommenterId)?.node}
                postingId={posting.id}
                createComment={createTruckPostingComment}
            />

            {!isMobile() && postingData && columnList.map((label, index) => {
                return (
                    <Row key={index}>
                        <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                            {label}
                        </Col>
                        <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                            {postingData[labelNameMapping[label]]}
                        </Col>
                    </Row>
                )
            })}

            {isMobile() && postingData && columnList.map((label, index) => {
                return (
                    <Row key={index} style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem 0', borderBottom: '1px solid lightgray'}}>
                        <Typography.Text style={{ fontSize: '0.8rem', color: '#888'}}>{label}</Typography.Text>
                        <Typography.Text>{postingData[labelNameMapping[label]]}</Typography.Text>
                    </Row>
                )
            })}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '1rem'}}>
                {traboxButton || null}
            </div>
        </Drawer>
    </>
};

export default DetailDrawer;
