import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
    Select,
    Space,
    TimePicker,
    Button,
    Modal,
    Form,
    message,
    Divider
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { GET_LABELS } from '../../queries';
import { CREATE_ROUTE_BASE } from '../../mutations';
import { useLazyQuery } from '@apollo/client';
import { dowDictionary } from '../../Utilities/dayOfWeek';
import { minTruckLoadDictionary } from '../../Utilities/minTruckLoad';
import { PlusCircleOutlined } from '@ant-design/icons';

const AddRouteBase = ({ stationsData, refetch, scenarioId, selectedStation }) => {
    const [getLabelsData, { data: labelsData, refetch: refetchLabels }] = useLazyQuery(GET_LABELS);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.
    const [createRouteBase] = useMutation(CREATE_ROUTE_BASE, {
        onCompleted: (data) => {
            message.success('ルートを追加しました。');
            setVisible(false);
            form.resetFields();
        },
        onError: (error) => {
            console.log(error);
            form.resetFields();
        }
    });

    const [stationId, setStationId] = useState(selectedStation);
    const [opsDow, setOpsDow] = useState([]);
    const [labelId, setLabelId] = useState();
    const [departureDow, setDepartureDow] = useState({});
    const [departureTime, setDepartureTime] = useState({});
    const [arrivalDow, setArrivalDow] = useState({});
    const [arrivalTime, setArrivalTime] = useState({});
    const [vehicleType, setVehicleType] = useState();
    const [minTruckLoad, setMinTruckLoad] = useState();
    const [parkedLoad, setParkedLoad] = useState();

    const handleClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        const results = []
        opsDow.forEach(async dow => {
            const res = await createRouteBase(
                {
                    variables: {
                        input: {
                            stationId,
                            opsDow: dow,
                            labelId,
                            departureDow: departureDow[dow],
                            departureTime: departureTime[dow],
                            arrivalDow: arrivalDow[dow],
                            arrivalTime: arrivalTime[dow],
                            vehicleType,
                            minTruckLoad,
                            parkedLoad,
                            scenarioId
                        }
                    }
                }
            );
            results.push(res);
            if (results.length === opsDow.length) {
                refetch();
            }
        })
        setOpsDow([])
        setLabelId(undefined)
        setDepartureDow(undefined)
        setArrivalDow(undefined)
        setDepartureTime(undefined)
        setArrivalTime(undefined)
        setVehicleType(undefined)
        setMinTruckLoad(undefined)
        setParkedLoad(undefined)
        setVisible(false)
    };

    useEffect(() => {
        getLabelsData({ variables: { stationId: selectedStation } })
    }, [selectedStation, getLabelsData]);

    useEffect(() => {
        forceUpdate({});
    }, []);

    const handleDowChange = value => {
        setOpsDow(value);

        const departureDowKeys = Object.keys(departureDow);

        // if the dow change was a deletion, remove the dow from the departureDow and arrivalDow
        if (departureDowKeys.length > value.length) {
            const keysToRemove = departureDowKeys.filter(key => !value.includes(parseInt(key)));

            const newDepartureDow = keysToRemove.reduce((obj, key) => {
                delete obj[key];
                return obj;
            }, { ...departureDow });
            const newDepartureTime = keysToRemove.reduce((obj, key) => {
                delete obj[key];
                return obj;
            }, { ...departureTime });
            const newArrivalDow = keysToRemove.reduce((obj, key) => {
                delete obj[key];
                return obj;
            }, { ...arrivalDow });
            const newArrivalTime = keysToRemove.reduce((obj, key) => {
                delete obj[key];
                return obj;
            }, { ...arrivalTime });
            setDepartureDow(newDepartureDow);
            setDepartureTime(newDepartureTime);
            setArrivalDow(newArrivalDow);
            setArrivalTime(newArrivalTime);
        }
    }

    return <>
        <Button onClick={() => { refetchLabels(); setVisible(true); }} icon={<PlusCircleOutlined/>}>追加</Button>
        <Modal
            title="固定ルートの追加"
            visible={visible}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose
            footer={null}
        >
            <Form
                name='request'
                form={form}
                onFinish={handleOk}
                layout='horizontal'
            >
                <Form.Item
                    label="営業所"
                    name='stationId'
                    rules={[{ required: false, message: '営業所を入力してください。' }]}
                >
                    <Select
                        disabled={true}
                        showSearch
                        defaultValue={selectedStation}
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        onChange={
                            (selectedId) => {
                                getLabelsData({ variables: { stationId: selectedId } })
                                setStationId(selectedId)
                            }
                        }
                    >
                        {
                            stationsData.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="曜日"
                    name='opsDow'
                >
                    <Select
                        showSearch
                        placeholder="選択してください（複数選択可）"
                        mode="multiple"
                        onChange={handleDowChange}
                    >
                        {
                            Object.entries(dowDictionary).map(
                                entry => (
                                    <Select.Option key={entry[0]} value={Number(entry[0])}>
                                        {entry[1]}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="ルート名"
                    name='label'
                >
                    <Select
                        showSearch
                        placeholder="選択してください"
						onChange={setLabelId}
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								|| option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
                    >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                    </Select>
                </Form.Item>
                {opsDow.map((dow, index) => {
                    return (
                    <div key={dow}>
                        <Divider orientation="left" plain>{dowDictionary[dow]}</Divider>
                        <Form.Item
                            label="出発時刻"
                            name='departure'
                        >
                            <Space>
                                <Select
                                    placeholder="曜日を選択" style={{ width: 120 }}
                                    onChange={(e) => {
                                        const newDepDow = {...departureDow}
                                        newDepDow[dow] = e
                                        setDepartureDow(newDepDow)
                                    }}
                                    defaultValue={dow}
                                >
                                    {
                                        Object.entries(dowDictionary).map(
                                            entry => (
                                                <Select.Option key={entry[0]} value={Number(entry[0])}>
                                                    {entry[1]}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                                <TimePicker onChange={(e) => {
                                    const newDeparture = {...departureTime}
                                    newDeparture[dow] = e
                                    setDepartureTime(newDeparture)
                                }} 
                                showTime={{ format: 'HH:mm' }} locale={locale}/>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            label="到着時刻"
                            name='arrival'
                        >
                            <Space>
                                <Select 
                                    placeholder="曜日を選択" style={{ width: 120 }}
                                    onChange={(e) => {
                                        const newArrDow = {...arrivalDow}
                                        newArrDow[dow] = e
                                        setArrivalDow(newArrDow)
                                    }}
                                    defaultValue={dow}
                                >
                                    {
                                        Object.entries(dowDictionary).map(
                                            entry => (
                                                <Select.Option key={entry[0]} value={Number(entry[0])}>
                                                    {entry[1]}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                                <TimePicker onChange={(e) => {
                                    const newArrivalTime = {...arrivalTime}
                                    newArrivalTime[dow] = e
                                    setArrivalTime(newArrivalTime)
                                }} showTime={{ format: 'HH:mm' }} locale={locale}/>
                            </Space>
                        </Form.Item>
                    </div>)
                })}
                <Form.Item
                    label="車種"
                    name='vehicleType'
                >
                    <Select onChange={setVehicleType} placeholder="選択してください">
                        {
                            ['冷凍車', '冷蔵車'].map(
                                vehicleType => (
                                    <Select.Option key={vehicleType} value={vehicleType}>
                                        {vehicleType}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="重量（キロ）"
                    name='minTruckLoad'
                >
                    <Select onChange={setMinTruckLoad} placeholder="選択してください">
                        {
                            Object.entries(minTruckLoadDictionary).map(
                                minTruckLoad => (
                                    <Select.Option key={minTruckLoad[1]} value={Number(minTruckLoad[1])}>
                                        {minTruckLoad[0]}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="荷有り"
                    name='parkedLoad'
                >
                    <Select onChange={setParkedLoad} placeholder="選択してください">
                        {
                            ["空", "荷あり"].map(
                                parkedLoadType => (
                                    <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                        {parkedLoadType}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate>
                    { () => (
                        <Button
                            disabled={ stationId === undefined ||
                                opsDow === undefined ||
                                labelId === undefined ||
                                departureDow === undefined ||
                                departureTime === undefined ||
                                arrivalDow === undefined ||
                                arrivalTime === undefined ||
                                vehicleType === undefined ||
                                minTruckLoad === undefined ||
                                opsDow.length !== Object.keys(departureDow).length ||
                                opsDow.length !== Object.keys(departureTime).length ||
                                opsDow.length !== Object.keys(arrivalDow).length ||
                                opsDow.length !== Object.keys(arrivalTime).length ||
                                parkedLoad === undefined ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                            type="primary"
                            htmlType='submit'
                        >
                            追加する
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    </>

};

export default AddRouteBase;
