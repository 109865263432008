import React from 'react';
import './App.less';
import { authenticationService } from './Utilities/authenticationService';
import { isMobile } from './Utilities/isMobile';
import {
    LoginForm,
    UserSettings,
    Labeling,
    RequestsList,
    Dashboard,
    PrivateRoute,
    TruckSchedule,
    TruckStats,
    RouteManagement,
    ForgotPassword,
    CreateNewPassword,
    Tutorial,
    AskQuestion,
    FreightPosting,
    ArchivedForecasts,
    TruckPosting,
    Simulation,
    Home
} from './Components';
import { PATHS, DENTIST_API_ROOT }  from './constants';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import history from './Utilities/history';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from '@apollo/client/link/context';
import MasterData from './Components/MasterData/MasterData';

class App extends React.Component {
    state = {
        currentUser: null
    };

    componentDidMount = () => {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    handleLogout = () => {
        authenticationService.logout();
        history.push('/login');
    }

    render = () => {
        const { currentUser } = this.state;
        const httpLink = new HttpLink({ uri: DENTIST_API_ROOT + '/graphql' });
        const authLink = setContext((_, { headers }) => {
            const token = localStorage.getItem('currentUser');
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${currentUser.token}` : ''
                }
            };
        });

        const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache()
        });

        return (
            <ApolloProvider client={client}>
                <Router basename={ process.env.PUBLIC_URL }>
                    <Switch>
                        {isMobile() && <Redirect exact from="/" to={PATHS.home} />}
                        <PrivateRoute path={ PATHS.home } component={Home} onLogout={this.handleLogout} title="ホーム"/>
                        <PrivateRoute disableBorder path={ PATHS.userSettings } component={UserSettings} onLogout={this.handleLogout} title="設定"/>
                        <PrivateRoute path={ PATHS.labeling } component={Labeling} onLogout={this.handleLogout} title="ラベルツール"/>
                        <PrivateRoute path={ PATHS.requestsList } component={RequestsList} onLogout={this.handleLogout} title="リクエスト一覧"/>
                        <PrivateRoute path={ PATHS.dashboard } component={Dashboard} onLogout={this.handleLogout} title="ダッシュボード"/>
                        <PrivateRoute path={ PATHS.truckSchedule } component={TruckSchedule} onLogout={this.handleLogout} title="運行スケジュール"/>
                        <PrivateRoute path={ PATHS.routeManagement } component={RouteManagement} onLogout={this.handleLogout} title="ルート管理"/>
                        <PrivateRoute path={ PATHS.truckStats } component={TruckStats} onLogout={this.handleLogout}/>
                        <PrivateRoute path={ PATHS.tutorial } component={Tutorial} onLogout={this.handleLogout} title="使い方"/>
                        <PrivateRoute path={ PATHS.askQuestion } component={AskQuestion} onLogout={this.handleLogout}/>
                        <PrivateRoute path={ PATHS.freightPosting } component={FreightPosting} onLogout={this.handleLogout} title="荷物掲示板"/>
                        <PrivateRoute path={ PATHS.archived } component={ArchivedForecasts} onLogout={this.handleLogout} title="削除済みルート"/>
                        <PrivateRoute path={ PATHS.truckPosting } component={TruckPosting} onLogout={this.handleLogout} title="空車掲示板"/>
                        <PrivateRoute path={ PATHS.simulation } component={Simulation} onLogout={this.handleLogout} title="シミュレーション"/>
                        <PrivateRoute path={ PATHS.masterData } component={MasterData} onLogout={this.handleLogout} title="データの追加"/>
                        <Route path={ PATHS.login } component={LoginForm}/>
                        <Route exact path={ PATHS.resetPassword } component={CreateNewPassword}/>
                        <Route exact path={ PATHS.forgotPassword } component={ForgotPassword}/>
                        <Route render={() => <Redirect to={PATHS.userSettings} />} />
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
