import { useState, useEffect } from 'react';

export const useStickyState = (defaultValue, key, params) => {
    const userKey = key + "_" + JSON.parse(localStorage.getItem('currentUser'))?.id;
    
    const [value, setValue] = useState(() => {
        // If params is passed, use it instead of localStorage
        if (params) {
            return params;
        }
        const stickyValue = window.localStorage.getItem(userKey);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    useEffect(() => {
        window.localStorage.setItem(userKey, JSON.stringify(value));
    }, [userKey, value]);

    return [value, setValue];
} 
