import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, message, Typography, Form, Input, Row, Space, Checkbox, Select} from 'antd';
import { CREATE_STATION } from '../../mutations';
import propTypes from 'prop-types';
import { authenticationService } from '../../Utilities/authenticationService';
import { GET_COMPANY } from '../../queries';
import { useQuery } from '@apollo/client';

const AddStationModal = ({ handleCompleted, returnToTable }) => {
    const currentUser = authenticationService.currentUserValue;
    const currentUserIsAdmin = currentUser?.admin

    const { data: companyData } = useQuery(GET_COMPANY);
    
    const [createStation] = useMutation(CREATE_STATION, {
        onCompleted: (data) => {
            handleCompleted(data.createStation.station);
        },
        onError: () => {
            message.error('会社名が既に登録されているか、書式が間違っています。');
        }
    });

    const onFinish = ({ stationName, officialName, isOpen, companyId, longtitude, latitude, district }) => {
        createStation({ variables: { input: { stationName, officialName, isOpen, companyId, longtitude, latitude, district } } });
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };

    
    
    return <Form
        labelCol={ { span: 4 } }
        wrapperCol={ { span: 8 } }
        name='newStation'
        form={form}
        style={{
            maxWidth: 600,
        }}
        onFinish={onFinish}
    >
        <Form.Item>
            <Typography.Title level={5}>営業所名追加</Typography.Title>
        </Form.Item>
        { currentUserIsAdmin && <Form.Item
            label="会社名"
            name='companyId'
            rules={[{ required: true, message: '会社IDを入力してください。' }]}
        >
            <Select>
                { companyData?.companies.edges.map(company => (
                    <Select.Option key={company.node.id} value={company.node.id}>
                        {company.node.companyName}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item> }
        <Form.Item
            label="営業所"
            name='stationName'
            rules={[{ required: true, message: '会社名を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="営業中"
            name='isOpen'
            valuePropName='checked'
            initialValue={true}
        >
            <Checkbox />
        </Form.Item>
        <Form.Item
            label="経度"
            name='longtitude'
        >
            <Input />
        </Form.Item>
        <Form.Item
            name='latitude'
            label="緯度"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name='district'
            label="地区"
            rules={[{ required: true, message: '地区を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        

        <Row>
            <Space>
                <Form.Item shouldUpdate {...tailFormItemLayout}>
                    { () => (
                        <Button
                            disabled={ !form.isFieldTouched('stationName') ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length}
                            type="primary"
                            htmlType='submit'
                        >
                            追加する
                        </Button>
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button onClick={returnToTable} >
                        キャンセル
                    </Button>
                </Form.Item>
            </Space>
        </Row>
    </Form>;
};

AddStationModal.propTypes = {
    handleCompleted: propTypes.func
};

export default AddStationModal;
