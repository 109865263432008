import React, { useEffect, useRef, useState } from 'react';
import { Drawer, List, Comment, Button, Input, Avatar } from 'antd';
import { authenticationService } from '../../Utilities/authenticationService';
import { isMobile } from '../../Utilities/isMobile';

const CommentDrawer = ({ open, setOpen, comments, selectedCommenterId, toUser, postingId, createComment }) => {
    const commentsEndRef = useRef(null);
    const currentUser = authenticationService.currentUserValue;
    const [newComment, setNewComment] = useState('');    
    const filteredComments = comments.filter(comment => (comment.toId === selectedCommenterId && comment.fromId === String(currentUser.id)) || (comment.toId === String(currentUser.id) && comment.fromId === selectedCommenterId))

    const onSubmit = () => {
        createComment ({
            variables: {
                input: {
                    postingId: postingId,
                    commentText: newComment,
                    toId: selectedCommenterId
                }
            }
        })
        setNewComment('');
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (commentsEndRef.current) {
                    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [open, comments]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            title={toUser?.lastName}
            style={{ zIndex: 1003 }}
            width={isMobile() ? '100vw': '400px'}
        >
            <div style={{ width: '100%', zIndex: 1 }}>
                <div style={{ flex: '1 1 auto', overflowY: 'auto', paddingBottom: '60px' }}>
                    <List>
                        {filteredComments.map((comment) => (
                            <Comment
                                author={comment.fromId === selectedCommenterId ? toUser?.lastName : null}
                                avatar={comment.fromId === selectedCommenterId ? <Avatar>{toUser?.lastName[0]}</Avatar> : null}
                                content={comment.content}
                                style={{ textAlign: parseInt(comment.fromId) === parseInt(currentUser?.id) ? 'right' : 'left' }}
                            />
                        ))}
                    </List>
                    <div ref={commentsEndRef} />
                </div>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: isMobile() ? 0 : 'calc(100% - 400px)',
                        width: isMobile() ? '100%' : '400px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Input.TextArea
                        rows={4}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                    />
                    <Button
                        style={{ height: "auto" }}
                        type="primary"
                        onClick={() => {
                            onSubmit();
                            setNewComment('');
                        }}  
                    >
                        送信
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

export default CommentDrawer;

