import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USERS, GET_COMPANY } from '../../queries';
import { UPDATE_USER } from '../../mutations'
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddUserModal from './AddUserModal';
import NewUserModal from './NewUserModal';
import DeleteUsersButton from './DeleteUsersButton';
import PasswordResetButton from './PasswordResetButton';

const isOwnStationUser = (edge, currentUser) => {
    return edge.node.station?.id === currentUser.station_id.toString();
}; 

const UserManagement = ({ stationsQuery }) => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newUserCreated, setNewUserCreated] = useState(false);
    const [usersDeleted, setUsersDeleted] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { data, error, loading, refetch } = useQuery(GET_USERS);
    const { data: companyData, loading: companyLoading } = useQuery(GET_COMPANY);
    const { data: stationsData, loading: stationsLoading, refetch: refetchStations } = stationsQuery;
    const currentUser = authenticationService.currentUserValue;
    const currentUserIsAdmin = currentUser?.company_admin || currentUser?.admin
    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: (data) => {
            const stationId = data.updateUser.user.station?.id || null;
            const companyId = data.updateUser.user.company?.id || null;
            localStorage.setItem('currentUser', JSON.stringify({...currentUser, ...data.updateUser.user, stationId, companyId }))
            refetchStations();
            refetch();
        },
        onError: (error) => { console.log(error); }
    });
    const [parsedCompanies, setParsedCompanies] = useState([]);
    const [parsedStations, setParsedStations] = useState([]);
    const [rows, setRows] = useState([]);

    const columns = [
        {
            title: <Typography.Text strong>氏名</Typography.Text>,
            dataIndex: 'fullName'
        },
        {
            title: <Typography.Text strong>メール</Typography.Text>,
            dataIndex: 'email'
        },
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName',
            render: (text, record) => {
                const handleStationSelect = (value) => {
                    const stationId = value || null;
                    updateUser({ variables: { input: { id: record.key, stationId: stationId, companyId: record.companyId}}})
                }
                return (
                    <Select allowClear bordered={false} style={{minWidth: "11rem"}} defaultValue={record.stationName} onChange={handleStationSelect} disabled={!currentUserIsAdmin}>
                        {parsedStations}
                    </Select>
                )
            },
        },
        {
            title: <Typography.Text strong>営業所管理者</Typography.Text>,
            dataIndex: 'stationManager',
            render: (text, record) => {
                const handleStationManagerSelect = (value) => {
                    updateUser({ variables: { input: { id: record.key, stationManager: value.target.checked}}})
                }
                return (
                    <input type="checkbox" checked={record.stationManager} onChange={handleStationManagerSelect} disabled={!currentUserIsAdmin}/>
                )
            }
        },
        {
            title: <Typography.Text strong>会社名</Typography.Text>,
            dataIndex: 'companyName',
            render: (text, record) => {
                const handleCompanySelect = (value) => {
                    const companyId = value || null;
                    updateUser({ variables: { input: { id: record.key, stationId: record.stationId, companyId: companyId}}})
                }
                return (
                    <Select allowClear bordered={false} style={{minWidth: "11rem"}} defaultValue={record.companyName} onChange={handleCompanySelect} disabled={!currentUserIsAdmin}>
                        {parsedCompanies}
                    </Select>
                )
            }
        },
        {
          title: <Typography.Text strong>会社管理者</Typography.Text>,  
            dataIndex: 'companyAdmin',
            render: (text, record) => {
                const handleCompanyAdminSelect = (value) => {
                    updateUser({ variables: { input: { id: record.key, companyAdmin: value.target.checked}}})
                }
                return (
                    <input type="checkbox" checked={record.companyAdmin} onChange={handleCompanyAdminSelect} disabled={!currentUserIsAdmin}/>
                )
            }
        },

        {
            dataIndex: 'passwordReset',
            className: 'align-right'
        }
    ];

    useEffect(() => {
        const { admin, company_admin, station_id } = currentUser;
        if (!stationsLoading){
            setParsedStations(stationsData.stations.edges.filter(edge => admin || company_admin || edge.node.id === String(station_id)).map( station => (
                    <Select.Option key={station.node.id} value={station.node.id} companyId={station.node.company.id}>
                        {station.node.officialName}
                    </Select.Option>
                )
            )); 
        }
    },[stationsData, stationsLoading, currentUser])
    
    useEffect(() => {
        if (!companyLoading){
            setParsedCompanies(companyData.companies.edges.map( company => (
                    <Select.Option key={company.node.id} value={company.node.id}>
                        {company.node.companyName}
                    </Select.Option>
                )
            ));
        }
    },[companyData, companyLoading])

    useEffect(() => {
        if (!loading) {
            setRows(
                data.users.edges.filter(edge => {
                    if (currentUserIsAdmin) {
                        return true;
                    }
                    return isOwnStationUser(edge, currentUser);
                }).map(
                    user => {
                        if (user.node.lastName.includes(searchText) ||
                            user.node.firstName.includes(searchText)) {
                            return {
                                key: user.node.id,
                                fullName: `${user.node.lastName} ${user.node.firstName}`,
                                email: user.node.email,
                                stationName: user.node.station?.officialName,
                                stationId: user.node.station?.id,
                                companyName: user.node.company?.companyName,
                                companyAdmin: user.node.companyAdmin,
                                stationManager: user.node.stationManager,
                                companyId: user.node.company?.id,
                                userId: user.node.id,
                                passwordReset: <PasswordResetButton userId={user.node.id} />
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean).sort((a, b) => a.key - b.key)
            )
        }
    }, [searchText, data, loading, currentUser, currentUserIsAdmin]);


    if (loading) { return <Skeleton />; }

    if (error) {
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const handleSearch = searchField => {
        setSearchText(searchField.target.value);
    };

    const TableHeader = <React.Fragment>
        <Row>
            <Col align='left' style={{ padding: '0px', background: '#fff' }}>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addUser') } icon={<PlusOutlined/>}>新規追加</Button>
                    <DeleteUsersButton
                        selectedUserIds={selectedUserIds}
                        handleCompleted={ (usersInfo) => { setCurrentModal('table'); setUsersDeleted(usersInfo); refetch(); }} 
                        disabled={!currentUserIsAdmin}/>
                </Space>
            </Col>
        </Row>
        <Row>
            { newUserCreated &&
                <>
                    <Col span={24}>
                        <Alert message="新規ユーザーを追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewUserModal userInfo={newUserCreated} />
                </>
            }
            { usersDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`ユーザーID "${usersDeleted.map(user => user.email)}"を削除しました。`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUserIds(selectedRows.map(row => row.userId));
        }
    };

    const returnToTable = () => {
        setCurrentModal('table');
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addUser' &&
            <AddUserModal
                parsedStations={parsedStations}
                parsedCompanies={parsedCompanies}
                handleCompleted={ (userInfo) => { setCurrentModal('table'); setNewUserCreated(userInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default UserManagement;
