import React, { useState } from 'react';
import { Space, Drawer, Form, Input, Select, InputNumber, Button, message, Modal } from 'antd';
import { CREATE_FREIGHT_POSTING } from '../../mutations';
import { useMutation } from '@apollo/client';
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';
import { truckWeight, truckModel } from '../FreightPosting/TraboxLabels';
import { isMobile } from '../../Utilities/isMobile';
import CustomSelect from '../../Utilities/customSelect';

const PostingDrawer = ({
    setNewPostingButtonLoading,
    drawerOpen,
    setDrawerOpen,
    stationsData,
    refetch }) => {
    const [stationsToNotify, setStationsToNotify] = useState([]);
    const [selectStationsModalOpen, setSelectStationsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [createFreightPosting] = useMutation(CREATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            message.success('荷物掲示板に投稿しました。')
            form.resetFields();
            setNewPostingButtonLoading(false);
        },
        onError: (error) => {
            setNewPostingButtonLoading(false);
            message.error('荷物掲示板への投稿に失敗しました。')
        }
    });

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 7,
            }
        }
    };

    const saveAsDraft = () => {
        form.validateFields()
            .then(values => {
                onFinish({...values, status: 'draft'})
            }
            )
            .catch(error => console.log(error))
    }

    const onFinish = (values, notify=false) => {
        const pickupDate = values.pickupDate;
        const deliveryDate = values.deliveryDate;

        if (pickupDate && deliveryDate && pickupDate >= deliveryDate) {
            message.error('納品日時は集荷日時より後でなければなりません。');
            return;
        }

        setSelectStationsModalOpen(false)
        setDrawerOpen(false);
        setNewPostingButtonLoading(true);
        createFreightPosting ({
            variables: {
                input: {
                    slipNumber: parseInt(values.slipNumber) || null,
                    stationId: values.station,
                    deliveryDate: values.deliveryDate,
                    pickupDate: values.pickupDate,
                    maxTruckCapacity: values.maxTruckCapacity,
                    vehicleType: values.vehicleType,
                    price: parseInt(values.price),
                    companyId: values.companyName,
                    memo: values.memo,
                    stationIds: notify ? stationsToNotify : null,
                    status: values.status || 'posted'
                }
            }
        })
    }
    return (<>
        <Drawer
            title="荷物掲示板に投稿する"
            placement="right"
            open={drawerOpen}
            width={isMobile() ? '100vw' : 600}
            onClose={() => setDrawerOpen(false)}
        >
            <Form
                form={form}
                name="request"
                onFinish={onFinish}
                initialValues={{ remember: true }}
                colon={false}
                layout="vertical"
            >
                <Form.Item label="依頼元営業所" name='station' rules={[{ required: true }]}>
                    <CustomSelect
                        style={{ width: 200 }}
                        placeholder="選択してください"
                        allowClear
                    >
                        {
                            stationsData?.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </CustomSelect>
                </Form.Item>
                <DatePickerWithTimeInForm label='集荷日時' name='pickupDate' />
                <DatePickerWithTimeInForm label='納品日時' name='deliveryDate' />
                <Form.Item label="積載重量" name='maxTruckCapacity' rules={[{ required: true }]}>
                    <CustomSelect
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={truckWeight.map(weight => ({ label: weight, value: weight }))}
                    />
                </Form.Item>
                <Form.Item label="車種" name='vehicleType' rules={[{ required: true }]}>
                    <CustomSelect
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={truckModel.map(model => ({ label: model, value: model }))}
                    />
                </Form.Item>
                <Form.Item label="料金" name='price' rules={[{ required: true }]}>
                    <InputNumber type='number' min={0} addonAfter='円'/>
                </Form.Item>
                <Form.Item label="伝票番号" name='slipNumber' rules={[{ required: false }]}>
                    <InputNumber type='number' min={0}/>
                </Form.Item>
                <Form.Item label="荷物の詳細" name='memo'>
                    <Input.TextArea 
                        placeholder = "(例) 集荷場所は〇〇、卸は〇〇です。連絡先は xxx-xxx-xxxx までお願いします。バラ貨物です。要フォークリフト資格です"
                        minRows = {10}
                        style = {{height: 180}}
                        />
                </Form.Item>
                <Form.Item shouldUpdate {...tailFormItemLayout}>
                    <Space size='middle' direction='horizontal'>
                        <Button type="secondary" htmlType="button" onClick={saveAsDraft}>下書き保存</Button>
                        <Button type="primary" htmlType="button" onClick={async () => await form.validateFields() && setSelectStationsModalOpen(true)}>
                            すぐに投稿する
                        </Button>
                    </Space>
                </Form.Item>
                <Modal
                    title="通知を送信する営業所を選択してください"
                    open={selectStationsModalOpen}
                    onCancel={() => setSelectStationsModalOpen(false)}
                    footer={[
                        <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: isMobile() && 'column' }}>
                            <Form.Item key='secondary' style={{marginRight: !isMobile() && '1rem'}}><Button type='secondary' htmlType="submit" onClick={() => onFinish(form.getFieldsValue())}>通知せずにそのまま投稿する</Button></Form.Item>
                            <Form.Item key='primary'><Button type='primary' htmlType="submit" onClick={() => onFinish(form.getFieldsValue(), true)}>通知を送信して投稿する</Button></Form.Item>
                        </div>
                    ]}
                >
                    営業所：<CustomSelect
                        mode="multiple"
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        onChange={e =>setStationsToNotify(e)}
                        
                        >
                        {
                            stationsData?.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </CustomSelect>
                </Modal>
            </Form>
        </Drawer>
    </>
    )
}

export default PostingDrawer;
