import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Form,
    Typography,
    Table,
    Spin,
    Space,
    Select,
    DatePicker,
    Row,
    Col,
    Modal
} from 'antd';
import { localeInDatepicker } from '../../Utilities/datepickerWithTimeInForm';
import { useQuery, useLazyQuery } from '@apollo/client';
import DetailDrawer from '../TruckPosting/DetailDrawer';
import PostingDrawer from '../TruckPosting/TruckSearch/PostingDrawer'
import { GET_STATIONS, GET_FORECAST_AVAILABLE_TRUCKS } from '../../queries';

import * as moment from 'moment';

const TruckSearchModal = ({ fromDate, toDate, station: propsStation, vehicle: propsVehicleId, visible, setVisible}) => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const [getRoutesData, { data: trucksData }] = useLazyQuery(GET_FORECAST_AVAILABLE_TRUCKS, { fetchPolicy: 'no-cache' });
    const [station, setStation] = useState(propsStation ? [propsStation] : undefined)
    const [date, setDate] = useState((fromDate && toDate && [moment(fromDate), moment(toDate)]) || '');
    const [rows, setRows] = useState([]);

    const postable = (start, end) => {
        if (start && end) {
        // make sure time interval is exactly 24 hours
        return moment(end).diff(moment(start), 'hours') % 24 === 0 && 
            moment(start).hour() === 0 && 
            moment(end).hour() === 0 &&
            moment(start).minute() === 0 &&
            moment(end).minute() === 0
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (station !== '' && fromDate !== undefined && toDate !== undefined) {
            getRoutesData({
                variables:  {
                    stationIds: propsStation ? station : station.flatten(),
                    fromDate: moment(fromDate).format('YYYY-MM-DD HH:mm:ss+09'),
                    toDate: moment(toDate).format('YYYY-MM-DD HH:mm:ss+09')
                }
            });
        }
    }, [getRoutesData, station, fromDate, toDate, propsStation])

    useEffect(() => {
        if (station !== '' && date !== '') {
            getRoutesData({
                variables:  {
                    stationId: station,
                    fromDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss+09'),
                    toDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss+09')
                }
            });
        }
    }, [getRoutesData, station, date])

    useEffect(() => {
        if (trucksData) {
            setRows(trucksData.forecastAvailableTrucks.map(truck => ({
                key: truck.id,
                id: truck.id,
                date: truck.opsDate,
                station: truck.station.officialName,
                stationId: truck.station.id,
                vehicleType: truck.vehicleType,
                vehicleId: truck.vehicleId,
                maxTruckCapacity: truck.maxTruckCapacity,
                driverLicenseClass: truck.driverLicenseClass
            })));
        }
    }, [trucksData])

    const handleDisabledDate = current => {
        return current <= moment().startOf('day') || current > moment().add(5, 'days')
    };

    const columns = [
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'station',
            width: 50
        }, {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            sorter: (a, b) => a.vehicleType && b.vehicleType && a.vehicleType.localeCompare(b.vehicleType, 'ja'),
            width: 50
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            width: 50
        }, {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            sorter: (a, b) => a.maxTruckCapacity - b.maxTruckCapacity,
            width: 50
        }, {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'driverLicenseClass',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
            width: 50
        }, {
            render: rowContent => <DetailDrawer key={rowContent} dateRange={date} stationsData={stationsData} truck={rowContent} paramStationId={station.length !== 0 && station[0]} paramsVehicleId={propsVehicleId} />,
            width: 100,
            fixed: 'right'
        }, date && postable(date[0], date[1]) && {
            render: rowContent => <PostingDrawer key={rowContent} dateRange={date} truck={rowContent} />,
            width: 100,
            fixed: 'right'
        }
    ].filter(Boolean);
    const [form] = Form.useForm();

    if (loadingStations) {
        return <Spin/>;
    }

    return <>
        <Modal title="空きトラック検索" open={visible} footer={null} width={window.innerWidth * 0.7} onCancel={() => setVisible(false)}>

        <Space size={24} direction='vertical' style={{ width: '100%' }}>
            <Card title={<Typography.Title level={3}>空きトラック検索</Typography.Title>}>
                <Form form={form} layout='inline'
                    size='large'
                    onFinish={
                        ({ date, station }) => {
                            console.log(station)
                            setStation(station.find(e => e === 'all') ? stationsData.stations.edges.map(e => e.node.id) : station);
                            setDate(date);
                        }
                    }
                    >
                    <Row gutter={[16,8]}>
                        <Col>
                            <Form.Item name='station' label="営業所">
                                <Select
                                    showSearch
                                    defaultValue={station}
                                    mode="multiple"
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                    >
                                    {
                                        stationsData.stations.edges.map(
                                            station => (
                                                <Select.Option key={station.node.id} value={station.node.id}>
                                                    {station.node.officialName}
                                                </Select.Option>
                                            )
                                            ).concat([<Select.Option key='all' value='all'>全ての営業所</Select.Option>])
                                        }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='date' label="表示期間">
                                <DatePicker.RangePicker
                                    locale={localeInDatepicker}
                                    disabledDate={handleDisabledDate}
                                    defaultValue={date || ''}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <>
                                            <Button
                                                disabled={
                                                    !form.getFieldValue('station') ||
                                                    !form.getFieldValue('date')
                                                }
                                                htmlType="submit" type='primary'>
                                                検索
                                            </Button>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Table
                columns={columns}
                style={{ padding: '24px', background: '#fff' }}
                dataSource={rows}
                scroll={{ x: 400 }}
                />
        </Space>
                </Modal>
    </>
};

export default TruckSearchModal;
