import { Button, DatePicker, Space, Tabs, Input, Row, message, Table } from 'antd';
import React, { useMemo } from 'react';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_PRESIGNED_UPLOAD_URL, GET_MASTER_FILES } from '../../queries';
import { CREATE_MASTER_FILE } from '../../mutations';
import localeInDatepicker from 'antd/es/date-picker/locale/ja_JP';

const MasterDataTab = ({ type }) => {
    const [files, setFiles ] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [date, setDate] = React.useState(null);
    
    const [createMasterFile] = useMutation(CREATE_MASTER_FILE,
        {
            onCompleted: () => {
                refetch();
            }
        }
    );
    
    const { data, refetch } = useQuery(GET_MASTER_FILES, {
        variables: { dataType: type }
    });
    
    const filteredData = useMemo(() => {
        if (!data) {
            return [];
        }
        let filteredRows = [...data.masterFiles]
        if (search) {
            filteredRows = filteredRows.filter(row => row.filename.includes(search));
        }
        if (date) {
            filteredRows = filteredRows.filter(row => row.createdAt.includes(date));
        }
        return filteredRows;
    }, [data, search, date]);
    
    const [getPresignedUploadUrl] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        onCompleted: (data) => {
            const newfiles = [...files];
            const [file, originalName] = newfiles.shift();
            setFiles(newfiles);
            const uploadUrl = data.presignedUploadUrl.uploadUrl
            if (file) {
                setLoading(true)
                uploadToS3(
                    {
                        file,
                        presignedUploadUrl: uploadUrl,
                        onError: (response) => {
                            console.log('ERROR!' + response.code);
                        },
                        onUploadReady: async () => {
                            setLoading(false)
                            message.success('アップロード完了');
                            console.log(file)
                            console.log(originalName)
                            createMasterFile(
                                {
                                    variables: { 
                                        input: {
                                            dataType: type,
                                            filename: originalName,
                                            s3ObjectKey: 'csv_uploads/' + type + '/' + file.name
                                        }
                                    }
                                }
                            );
                        }
                    }
                );
            }
        }
    });
    
    
    const { getRootProps, getInputProps } = useS3Upload({
        onUploadStart: acceptedFiles => {
            // Preload all files here in a stack 'files'
            // append timestamp to filename to avoid duplication
            const newFiles = acceptedFiles.map(file => {
                const n = file.name.lastIndexOf('.');
                const firstHalf = file.name.slice(0, n);
                const secondHalf = file.name.slice(n);
                const newName = firstHalf + '_' + String(Date.now()) + secondHalf;
                return [new File([file], newName, {type: file.type}), file.name];
            })
            setFiles(newFiles);
            getPresignedUploadUrl({ variables: { objectKey: 'csv_uploads/' + type + '/'+ newFiles[0][0].name } });
        }
    });
    
    const columns = [
        {
            title: 'ファイル名',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'アップロード日時',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            dataIndex: 'downloadUrl',
            key: 'actions',
            render: (text) => (
                <Space size="middle">
                    <a href={text}>ダウンロード</a>
                </Space>
            ),
        },
    ];
    
    return <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "start"}}>
            <Input.Search placeholder="検索" style={{ width: 400, marginRight: "1rem" }} onChange={e => setSearch(e.target.value)}/>
            <DatePicker 
                locale={localeInDatepicker}
                picker='date' onChange={(_, dateString) => setDate(dateString)}
            />
            <div
                style={{marginLeft: "auto"}}
                {...getRootProps()}>
                <input {...getInputProps()} />
    
                <Row justify='center'>
                    <Button loading={loading} type="primary" >CSVアップロード</Button>
                </Row>
            </div>
        </div>
        <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            bordered
        />
    </Space>
}

export default function MasterData() {
    
    const items = [
        {
            key: 'driveData',
            label: 'ドライブデータ',
            children: <MasterDataTab type={'drive'} title='ドライブデータ'/>,
        },
        {
            key: 'truckData',
            label: 'トラックデータ',
            children: <MasterDataTab type={'truck'} title='トラックデータ' />,
        },
        {
            key: 'driverData',
            label: 'ドライバーデータ',
            children: <MasterDataTab type={'driver'} title='ドライバーデータ'/>,
        },
    ];
    
    return (
        <div style={{ margin: "1rem 2rem" }}>
            <Tabs
                items={items}
            />
        </div>
    );
}